<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
			}
		},
		async mounted() {
			await this.ready();
          	this.$set(this.$root, 'page', this);
		},
	};
</script>
<template>
	<div>
		<section id="slider" class="slider-element d-none d-sm-block d-md-block d-lg-block clearfix" :style="'height: 570px; padding: 30px 0; background: url('+baseUrl+'/frontend/images/hero-yayasan.jpg) no-repeat 50% center;'">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 m_top_80">
                        <div class="wrap_hero_txt">
                            <h2>Profil Yayasan</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="d-sm-none d-md-none d-lg-none" style="margin-top: 60px;"> 
        	<VImg :src="baseUrl+'/frontend/images/about-mobile.jpg'" alt="Profil Yayasan" title="Profil Yayasan"></VImg>
        </section>
			<section id="content" style="overflow: visible">
			<div class="content-wrap p-0">
			    <div class="clear"></div>
			    <section class="section notopmargin nobottommargin">
			        <div class="container clearfix">
			            <div class="row justify-content-center">
			                <div class="col-md-11">
			                    <div class="row">
			                        <div class="col-md-1 center ">
			                            <div class="wrap_vision">
			                                <h3>Visi</h3>
			                            </div>
			                        </div>
			                        <div class="col-md-2">
			                        </div>
			                        <div class="col-md-9 center ">
			                            <div class="wrap_vision">
			                                <p>“Menjadi lembaga sosial yang terdepan dalam pengembangan mutu pendidikan dan pembangunan berkelanjutan di Indonesia”
			                                </p>
			                            </div>
			                        </div>
			                    </div>
			                </div>
			            </div>
			        </div>
			    </section>
			    <section class="section nobg  notopmargin nobottommargin">
			        <div class="container clearfix">
			            <div class="row justify-content-center">
			                <div class="col-md-11">
			                    <div class="row">
			                        <div class="col-md-1 center">
			                            <div class="wrap_vision">
			                                <h3>Misi</h3>
			                            </div>
			                        </div>
			                        <div class="col-md-2">
			                        </div>
			                        <div class="col-md-9 center">
			                            <div class="wrap_vision">
			                                <ul>
			                                    <li>Mengimplementasikan berbagai program pengembangan mutu pendidikan di berbagai wilayah Indonesia dengan membangun kerjasama dan kemitraan dengan multi pihak</li>
			                                    <li>Menumbuhkembangkan kesadaran publiK dan berperan serta aktif mendorong terwujudnya konsep tentang pendidikan yang bermutu & pembangunan berkelanjutan baik di skala nasional maupun regional
			                                    </li>
			                                    <li>Melakukan kajian dan diseminasi informasi terkait pendidikan dan pengembangan berkelanjutan</li>
			                                    <li>Mengembangkan kerjasama multipihak dan memperkuat jejaring pembangunan berkelanjutan di suatu wilayah tertentu</li>
			                                    <li>Memfasilitasi program pemberdayaan masyarakat termasuk tanggung jawab sosial perusahaan di berbagai wilayah yang memperkuat tercapainya tujuan pembangunan berkelanjutan
			                                    </li>
			                                </ul>
			                            </div>
			                        </div>
			                    </div>
			                </div>
			            </div>
			        </div>
			    </section>
			    <section class="section  notopmargin nobottommargin">
			        <div class="container clearfix">
			            <div class="row justify-content-center">
			                <div class="col-md-11">
			                    <div class="heading_section text-center">
			                        <h2>Kepemimpinan Yayasan</h2>
			                    </div>
			                </div>
			            </div>
			            <div class="row justify-content-center">
			                <div class="col-md-12">
			                    <div class="title_org">
			                        <p>Dewan Pembina</p>
			                    </div>
			                    <div class="row justify-content-center">
			                        <div class="col-md-12">
			                            <div class="wrap_profile">
			                                <div class="row">
			                                    <div class="col-md-3">
			                                        <div class="thumb_post">
			                                        	<VImg :src="baseUrl+'/frontend/images/team/juli_adrian.jpg'" alt="Juli Adrian"></VImg>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-9">
			                                        <div class="wrap_profile_content">
			                                            <h3>Juli Adrian</h3>
			                                            <h4>Ketua</h4>
			                                            <p>
			                                                Setelah lulus dari Universitas Trisakti jurusan Manajemen Sumber
			                                                Daya Manusia pada tahun 1997, ia membangun karirnya di beberapa
			                                                perusahaan multinasional sebagai Manajer HRD dan juga di
			                                            perusahaan konsultan sebagai Konsultan HRD Senior.</p>
			                                            <p>
			                                                Didorong oleh hasratnya yang kuat di bidang pendidikan, pada
			                                                tahun 2002 ia ikut mendirikan ProVisi Education - konsultan
			                                                pendidikan &amp; penyedia layanan yang berfokus pada peningkatan
			                                                kualitas pendidikan di Indonesia. Di bawah kepemimpinannya
			                                                sebagai Direktur Pelaksana, ProVisi Education telah menerapkan
			                                                berbagai program peningkatan kualitas pendidikan di lebih dari
			                                                200 wilayah di seluruh Indonesia, membawa dampak signifikan bagi
			                                                lebih dari 1.000 sekolah, 20.000 guru, dan 100.000 siswa, dalam
			                                                kemitraan dengan lebih dari 100 CSR perusahaan multinasional,
			                                                organisasi internasional dan pemangku kepentingan daerah.
			                                            </p>
			                                            <p>
			                                                Kemudian ia menyadari bagaimana teknologi informasi dapat
			                                                membantu meningkatkan kualitas pembelajaran, pada tahun 2017 ia
			                                                turut mendirikan Shakta Vidya Solusi, konsultan IT pendidikan
			                                                dan integrator sistem yang berfokus pada penyediaan berbagai
			                                                solusi IT untuk pendidikan dasar, menengah, dan tinggi. Saat
			                                                ini, ia memegang posisi sebagai Komisaris di perusahaan.
			                                            </p>
			                                            <p>
			                                                Menjalankan semangatnya untuk berkontribusi secara signifikan
			                                                pada peningkatan kualitas pendidikan, sejak 2007 ia pun turut
			                                                terlibat dengan Yayasan Bina Sarana Bakti, sebuah yayasan
			                                                perintis dalam pertanian organik dengan misi untuk mendidik dan
			                                                memelihara keharmonisan cara hidup melalui pertanian. Saat ini,
			                                                ia memegang posisi Ketua Dewan Pengawas di yayasan.
			                                            </p>
			                                        </div>
			                                    </div>
			                                </div>
			                            </div>
			                        </div>
			                        <div class="col-md-12">
			                            <div class="wrap_profile">
			                                <div class="row">
			                                    <div class="col-md-3">
			                                        <div class="thumb_post">
			                                        	<VImg :src="baseUrl+'/frontend/images/team/romy_cahyadi.jpg'" alt="Romy Cahyadi"></VImg>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-9">
			                                        <div class="wrap_profile_content">
			                                            <h3>Romy Cahyadi</h3>
			                                            <h4>Anggota</h4>
			                                            <p>Romy Cahyadi adalah mitra pendiri dan Chief Executive Officer Instellar, sebuah perusahaan yang digerakkan oleh tujuan untuk membantu dunia bisnis agar dijalankan dengan tanggung jawab sosial dan digerakkan demi kebaikan bagi masyarakat dan lingkungan. </p>
			                                            <p>Sejak sepuluh tahun terakhir ia bergerak di bidang kewirausahaan sosial, termasuk sebagai direktur Social Enterprise International (wilayah Asia Tenggara), salah satu pendiri UnLtd Indonesia (salah satu pionir inkubator wirausaha sosial di Indonesia), dan ikut mendirikan Asosiasi Kewirausahaan Sosial Indonesia. </p>
			                                            <p>Romy Cahyadi banyak diundang sebagai panelis di berbagai konferensi regional, seperti Asia Venture Philanthropy Network (AVPN) Conference, dan Asia Network of Young Social Entrepreneurs (Korea Selatan). Dia dipilih sebagai seorang Fellow oleh Social Enterprise Research Academy (Hong Kong) pada 2018.</p>
			                                        </div>
			                                    </div>
			                                </div>
			                            </div>
			                        </div>
			                    </div>
			                    <div class="row justify-content-center">
			                        <div class="col-md-12">
			                            <div class="wrap_profile">
			                                <div class="row">
			                                    <div class="col-md-3">
			                                        <div class="thumb_post">
			                                        	<VImg :src="baseUrl+'/frontend/images/team/leo.jpg'" alt="Leo Tansari"></VImg>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-9">
			                                        <div class="wrap_profile_content">
			                                            <h3>Leo Tansari</h3>
			                                            <h4>Anggota</h4>
			                                            <p>Mulai bergiat di bidang sosial, terutama di bidang pendidikan sejak tahun 2002.
			                                                Selama 18 tahun berkarya, Leo E. Tansari telah melatih ribuan siswa.siswi dari berbagai tingkatan sekolah pada aspek karakter, ketrampilan berorganisasi, management diri, dan pemilihan jurusan.
			                                            </p>
			                                            <p>Sebagai Ketua Steering Committee Konferensi Guru Nusantara,
			                                                Leo menyelenggarakan Konferensi Guru yang diikuti guru-guru dari
			                                                seluruh nusantara dan Negara-negara tetangga pada tahun  2008 sampai dengan 2011.
			                                            </p>
			                                            <p>
			                                                Dalam hal peningkatan kompetensi dan profesionalisme Guru, lewat Gerakan Guru Swadaya, Leo  mengkoordinir lebih dari 100 forum pertemuan, forum diskusi, dan pelatihan Guru. Bersamaan  dengan itu pula, Leo melakukan program pemberdayaan guru melalui pembiayaan lunak kepada Guru-Guru agar mampu mengembangkan diri secara mandiri dan Swadaya.
			                                            </p>
			                                        </div>
			                                    </div>
			                                </div>
			                            </div>
			                        </div>
			                        <div class="col-md-12">
			                            <div class="wrap_profile">
			                                <div class="row">
			                                    <div class="col-md-3">
			                                        <div class="thumb_post">
			                                        	<VImg :src="baseUrl+'/frontend/images/team/andreas.jpg'" alt="Andreas Ismar"></VImg>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-9">
			                                        <div class="wrap_profile_content">
			                                            <h3>Andreas Ismar</h3>
			                                            <h4>Anggota</h4>
			                                            <p>Wartawan keuangan sejak 2007, dengan pengalaman di kantor berita Reuters,
			                                                Dow Jones, serta Cogencis. Membumikan konsep-konsep ekonomi
			                                                makro serta pasar modal untuk meningkatkan literasi keuangan khalayak luas.
			                                            </p>
			                                            <p>
			                                                Memiliki ketertarikan di bidang konservasi hayati serta agroekonomi, saat ini sedang mengembangkan konsep pertanian berkesinambungan yang memiliki dampak positif terhadap kesejahteraan petani. Dengan penguatan kelembagaan serta jejaring, maka petani bergaji menteri bukanlah impian siang bolong.
			                                            </p>
			                                        </div>
			                                    </div>
			                                </div>
			                            </div>
			                        </div>
			                    </div>
			                </div>
			            </div>
			            <div class="row justify-content-center">
			                <div class="col-md-12">
			                    <div class="title_org">
			                        <p>Dewan Pengurus</p>
			                    </div>
			                    <div class="row justify-content-center">
			                        <div class="col-md-12">
			                            <div class="wrap_profile">
			                                <div class="row">
			                                    <div class="col-md-3">
			                                        <div class="thumb_post">
			                                        	<VImg :src="baseUrl+'/frontend/images/team/rosma.jpg'" alt="Rusmawati Siadari"></VImg>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-9">
			                                        <div class="wrap_profile_content">
			                                            <h3>Rusmawati Siadari</h3>
			                                            <h4>Wakil Ketua</h4>
			                                            <p>Memiliki ketertarikan terhadap dunia sosial sejak masa kuliah dan mulai membangun karier sebagai pendidik formal sejak tahun 2002. Tahun 2010, memutuskan untuk terjun langsung membantu pendidikan di daerah sulit; mengimplementasi pembelajaran kontekstual, mendampingi sekolah, kepala sekolah, guru dan siswa secara teknis, membangun hubungan dengan masyarakat dan melakukan advokasi pada pemerintah setempat.
			                                            </p>
			                                            <p> Saat ini, lebih konsentrasi membangun kemitraan pendidikan dengan berbagai pihak, mendesain program dan proyek yang relevan dengan kebutuhan pendidikan setempat, melakukan kajian pada wilayah atau sekolah potensial, dan mendesain kurikulum kontekstual yang akan membantu sekolah atau institusi pendidikan di daerah sulit.
			                                            </p>
			                                        </div>
			                                    </div>
			                                </div>
			                            </div>
			                        </div>
			                        <div class="col-md-12">
			                            <div class="wrap_profile">
			                                <div class="row">
			                                    <div class="col-md-3">
			                                        <div class="thumb_post">
			                                        	<VImg :src="baseUrl+'/frontend/images/team/eka.jpg'" alt="Katherine Eka Laila"></VImg>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-9">
			                                        <div class="wrap_profile_content">
			                                            <h3>Katherine Eka Laila</h3>
			                                            <h4>Sekretaris I</h4>
			                                            <p>
			                                                Memiliki ketertarikan terhadap dunia sosial dan pendidikan sejak tahun 2011
			                                                ketika bergabung di sebuah perusahaan jasa konsultan pendidikan dan menangani
			                                                hubungan kemitraan dengan berbagai pihak seperti CSR perusahaan, NGO
			                                                internasional, Yayasan, hingga pemerintah daerah. Selain itu juga aktif
			                                                melakukan branding maupun kampanye di bidang pengembangan mutu pendidikan di
			                                                Indonesia melalui media sosial.
			                                            </p>
			                                            <p>
			                                                Pernah menjadi koordinator dalam sebuah komunitas sosial di Jakarta Pusat
			                                                yang fokus pada pendampingan Anak Berkebutuhan Khusus (ABK). Selain itu
			                                                pernah juga menjadi koordinator dalam program 'Global Learning Journey'
			                                                mendampingi 11 orang peserta dari Jepang yang datang secara khusus ke
			                                                Indonesia untuk menjalankan misi menyebarluaskan semangat kualitas hidup
			                                                kepada masyarakat terpinggir di ibukota.
			                                            </p>
			                                            <p>
			                                                Saat ini juga memiliki perhatian khusus pada isu-isu perempuan, kesetaraan gender dan
			                                                pembangunan karakter anak usia dini.
			                                            </p>
			                                        </div>
			                                    </div>
			                                </div>
			                            </div>
			                        </div>
			                        <div class="col-md-12">
			                            <div class="wrap_profile">
			                                <div class="row">
			                                    <div class="col-md-3">
			                                        <div class="thumb_post">
			                                        	<VImg :src="baseUrl+'/frontend/images/team/maksudi.jpg'" alt="Maksudi"></VImg>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-9">
			                                        <div class="wrap_profile_content">
			                                            <h3>Maksudi</h3>
			                                            <h4>Sekretaris II</h4>
			                                            <p>Ketertarikan dalam dunia sosial dimulai pada saat kuliah dengan melakukan pemberdayaan masyarakat hutan desa bersama Mapala, pada tahun 2010 mulai terlibat dengan kegiatan pemberdayaan masyarakat secara professional berasama BINA SWADAYA dalam berbagai program  CSR perusahaan di Kalimantan Timur,  Maluku Utara dan Jawa Timur.
			                                            </p>
			                                            <p>Pada pertengahan tahun 2015 sampai dengan 2017 terlibat dalam pengelolaan  program pengembangan UMKM bersama UKM Center UI sebagai Community Development Expert dan pertengahan 2017- 2018 bergabung di perusahaan sebagai CSR Specialist, saat ini sedang menyelesaikan Magister Management Magister Manajemen CSR/ Sustainability di Trisakti.
			                                            </p>
			                                        </div>
			                                    </div>
			                                </div>
			                            </div>
			                        </div>
			                    </div>
			                </div>
			            </div>
			        </div>
			    </section>
			</div>
		</section>
	</div>
</template>